import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  ButtonToolbar,
  Col,
  Form,
  FormGroup,
  Glyphicon,
  Modal,
  Row,
} from 'react-bootstrap'
import { validated } from 'react-custom-validation'
import moment from 'moment'
import { find, has, isEqual, isNumber, isString, map, set } from 'lodash'
import qs from 'qs'
import {
  booleanOptions,
  contractTypes,
  countries,
  currencyValues,
  departments,
  generateKeys,
  legalForms,
  offices,
  salaryForms,
  statuses,
  subcontractTypes,
} from './common/enums'
import Field from './components/Field'
import {
  companyDetailsFields,
  companyOnlyDetailsFields,
  contractFields,
  employeeFieldNames,
  employeeFieldsEditable,
  employmentDetailsFields,
  employmentFieldsEditable,
  filterDepartmentOptions,
  filterSubdepartmentOptions,
  filterVlCompanyOptions,
  getSubcontractFields,
  getExtendedSubcontractFields,
  nonEmploymentFieldsEditable,
  personalDetailsFields,
} from './employeeFields'
import SessionExpiredDialog from './components/SessionExpiredDialog'
import { ContractConflictEditor, getConflicts } from './ContractConflictEditor'
import { EmployeeSubcontractsEditor } from './EmployeeSubcontractsEditor'
import {
  apiRequest,
  dateFormatHuman,
  getConfig,
  getFteEquivalent,
  getFullName,
  getFullNameReversed,
  getSalaryEur,
  getSuperGrossSalary,
  handleUncaughtError,
  isFreelancing,
  isSomeEmployeeLegalFormEmployment,
  isSomeEmployeeLegalFormNotEmployment,
  notifications,
  pushNotification,
  sessionExpired,
  trimSpaces,
} from './utils'
import NotificationContext from 'utils/context/NotificationContext'
import styles from './EmployeeEditor.module.scss'

const baseCategories = [
  personalDetailsFields,
  [...contractFields, employeeFieldNames.comment],
]

export const formatValues = (values) => {
  values.validFrom =
    values.validFrom && !moment.isMoment(values.validFrom)
      ? moment.utc(values.validFrom)
      : values.validFrom
  values.startingDate =
    values.startingDate && !moment.isMoment(values.startingDate)
      ? moment.utc(values.startingDate)
      : values.startingDate
  values.birthDate =
    values.birthDate && !moment.isMoment(values.birthDate)
      ? moment.utc(values.birthDate)
      : values.birthDate
  values.contractDate =
    values.contractDate && !moment.isMoment(values.contractDate)
      ? moment.utc(values.contractDate)
      : values.contractDate
  values.contractExpiration =
    values.contractExpiration && !moment.isMoment(values.contractExpiration)
      ? moment.utc(values.contractExpiration)
      : values.contractExpiration

  values.isActive = values.isActive
    ? statuses.active.key
    : statuses.inactive.key
  values.selfBilling = values.selfBilling
    ? booleanOptions.true.key
    : booleanOptions.false.key

  for (const field of [
    'isStudent',
    'levyExemption',
    'nonTaxableBase',
    'legacyShares',
    'rollingShares',
    'sabbatical',
    'isUniversityStudent',
    'isHighScoolStudent',
    'taxBonusOnChildren',
    'deductionsAndForeclosures',
    'illnessTaxExemption',
    'familyTaxExemption',
    'paysChildCareFee',
  ]) {
    if (values[field] != null) {
      values[field] = values[field]
        ? booleanOptions.true.key
        : booleanOptions.false.key
    }
  }

  if (!values.emailVL) {
    values.emailVL = ''
  }
  if (!values.emailOld1) {
    values.emailOld1 = ''
  }
  if (!values.emailOld2) {
    values.emailOld2 = ''
  }

  const obsoleteFields = []
  if (values.department && !departments[values.department]) {
    values.department = null
    obsoleteFields.push(employeeFieldNames.department.label)
  }
  if (values.office && !offices[values.office]) {
    values.office = null
    obsoleteFields.push(employeeFieldNames.office.label)
  }
  if (values.legalForm && !legalForms[values.legalForm]) {
    values.legalForm = null
    obsoleteFields.push(employeeFieldNames.legalForm.label)
  }
  if (values.salaryForm && !salaryForms[values.salaryForm]) {
    values.salaryForm = null
    obsoleteFields.push(employeeFieldNames.salaryForm.label)
  }

  return {
    values: {
      ...values,
      subcontracts: values.subcontracts ? [...values.subcontracts] : [],
    },
    obsoleteFields,
  }
}

export const prepareUpdate = async (values, employee, companyOptions = []) => {
  const contractDoesNotExist = employee.isActive === null
  const originalValues = contractDoesNotExist
    ? { ...employee, ...employee.nextContract } // TODO: next steps - check subcontract handling here
    : employee
  const active = values.isActive === statuses.active.key
  const isSomeLegalFormEmployment = isSomeEmployeeLegalFormEmployment(values)
  const isSomeLegalFormNotEmployment =
    isSomeEmployeeLegalFormNotEmployment(values)

  const changedData = {}

  // add changed fields from employee, main contract and legalForm data
  employeeFieldsEditable.forEach(
    ({
      name,
      options,
      parse,
      contractField,
      employmentDetail,
      companyDetail,
      omitInDatabase,
    }) => {
      if (omitInDatabase) {
        return
      }
      let value
      try {
        // parse may throw exception if the value does not exist in the map of options
        value = trimSpaces(parse ? parse(values[name]) : values[name])
      } catch {
        value = values[name]
      }
      const originalValue = parse
        ? parse(originalValues[name])
        : originalValues[name]
      const valueHasChanged = value !== originalValue
      const editableBasedOnStatus =
        active ||
        !contractField ||
        name === 'isActive' ||
        name === 'note' ||
        name === 'reasonForLeaving' ||
        name === 'departure'
      const editableBasedOnLegalForm =
        (isSomeLegalFormEmployment && employmentDetail) ||
        (isSomeLegalFormNotEmployment && companyDetail) ||
        (!employmentDetail && !companyDetail) ||
        name === 'companyId' ||
        name === 'employer'

      // skip unchanged fields or contract fields when making employee inactive or
      // employment details fields when legal form doesn't allow them
      if (
        valueHasChanged &&
        editableBasedOnStatus &&
        editableBasedOnLegalForm
      ) {
        changedData[name] = value
      }
    },
  )

  const changedSubcontracts = []

  values.subcontracts.forEach((subcontract) => {
    const subcontractFields = getExtendedSubcontractFields(subcontract)

    // add changes from existing subcontract
    if (subcontract.contractId) {
      const contractChanges = { id: subcontract.contractId }
      const originalSubcontract = find(originalValues.subcontracts, [
        'contractId',
        subcontract.contractId,
      ])

      subcontractFields.forEach(({ name, parse, omitInDatabase }) => {
        if (omitInDatabase) {
          return
        }

        const value = trimSpaces(
          parse ? parse(subcontract[name]) : subcontract[name],
        )
        const originalValue = parse
          ? parse(originalSubcontract[name])
          : originalSubcontract[name]
        if (value !== originalValue && active) {
          contractChanges[name] = value
        }
      })
      if (Object.keys(contractChanges).length > 1) {
        changedSubcontracts.push(contractChanges)
      }
    } else {
      // add new subcontract
      const parsedSubcontract = {}
      subcontractFields.forEach(({ name, parse }) => {
        const value = trimSpaces(subcontract[name])
        if (value != null) {
          parsedSubcontract[name] = parse ? parse(value) : value
        }
      })
      changedSubcontracts.push(parsedSubcontract)
    }
  })

  //check for removed subcontracts
  const newSubcontractsIds = map(values.subcontracts, 'contractId')
  map(originalValues.subcontracts, 'contractId').forEach((subcontractId) => {
    if (!newSubcontractsIds.includes(subcontractId)) {
      changedSubcontracts.push({ id: subcontractId })
    }
  })

  changedData.subcontracts = changedSubcontracts

  const companyIdMapper = companyOptions.reduce((mapper, { key, label }) => {
    mapper[key] = label
    return mapper
  }, {})
  const uiValueMapper = { companyId: companyIdMapper }

  const data = {
    id: values.id,
    validFrom: values.validFrom,
    ...changedData,
  }

  const { conflictChanges, fieldsToChange } = await getConflicts(
    data,
    employee,
    contractDoesNotExist,
    changedData.length === 1 && has(changedData, 'validFrom'),
    uiValueMapper,
  )

  return { data, changedData, conflictChanges, fieldsToChange }
}

export default class EmployeeEditor extends Component {
  static contextType = NotificationContext

  static propTypes = {
    show: PropTypes.bool.isRequired,
    date: PropTypes.object,
    updating: PropTypes.bool.isRequired,
    employee: PropTypes.object.isRequired,
    employees: PropTypes.arrayOf(PropTypes.object).isRequired,
    reloadEditorData: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    const defaultFormState = this.getDefaultFormState()

    this.state = {
      companyOptions: {},
      ...defaultFormState,
    }
  }

  componentDidUpdate(prevProps) {
    const { employee, updating, date } = this.props

    if (prevProps.show !== this.props.show) {
      this.clearForm()
    }

    if (updating && !isEqual(employee, prevProps.employee)) {
      this.getEmployeeCompanies(employee.id)
      let values = { ...employee }
      if (this.state.values && this.state.values.validFrom) {
        // keep selected validFrom
        values.validFrom = this.state.values.validFrom
      }

      let contractDoesNotExist = false
      if (values.isActive === null) {
        // no existing contract to that date, use nextContract values
        const validFrom = values.validFrom || date
        values = { ...values, ...employee.nextContract, validFrom }
        contractDoesNotExist = true
      }

      this.updateCategories(
        values.legalForm,
        values.employer,
        values.subcontracts,
      )

      values = formatValues(values).values

      values = this.recalculateValues(values)

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        values,
        updating: true,
        contractDoesNotExist,
        confirmUpdate: {
          show: false,
          data: null,
          onUpdate: null,
          conflictChanges: null,
        },
      })
    }

    if (this.props.show) {
      if (!prevProps.show && !updating) this.getDrafts()
      if (!this.polling && updating) {
        this.polling = window.setInterval(this.reloadData, getConfig().polling)
      }
    } else if (this.polling) {
      window.clearInterval(this.polling)
      this.polling = null
    }
  }

  componentWillUnmount() {
    if (this.polling) {
      window.clearInterval(this.polling)
      this.polling = null
    }
  }

  getDefaultFormState = () => ({
    values: {
      validFrom: this.props.date,
      isActive: statuses.active.key,
      country: countries.sk.key,
      contractType: contractTypes.internal.key,
      currency: currencyValues.EUR.key,
      salaryBonus: 0,
      officeTime: 0,
      equipmentBonus: 0,
      sharePurchase: 0,
      shareBonus: 0,
      sparringShareBonus: 0,
      terminationPeriod: 1,
      nationality: countries.sk.key,
      isStudent: booleanOptions.false.key,
      levyExemption: booleanOptions.false.key,
      nonTaxableBase: booleanOptions.false.key,
      emailVL: '',
      emailOld1: '',
      emailOld2: '',
      legacyShares: booleanOptions.false.key,
      rollingShares: booleanOptions.false.key,
      sabbatical: booleanOptions.false.key,
      subcontracts: [],
    },
    updating: false,
    showCategories: this.getCategories(),
  })

  clearForm() {
    this.setState(this.getDefaultFormState())
  }

  reloadData = (date = this.state.values.validFrom) => {
    this.props.reloadEditorData(date)
  }

  getDrafts = () => {
    apiRequest('drafts')
      .then((response) => {
        const drafts = generateKeys(
          response.reduce((drafts, { id, firstName, lastName, fields }) => {
            drafts[id] = {
              label: getFullName({ firstName, lastName }),
              fields,
            }
            return drafts
          }, {}),
        )
        this.setState({ drafts })
      })
      .catch((err) => handleUncaughtError(err, this.context.addNotification))
  }

  upsertDraft = (id) => {
    const data = this.parseValues(this.state.values, true)

    const options = {
      method: 'POST',
      body: {
        ...data,
        id: id || undefined,
      },
    }

    return apiRequest('drafts', options).then(() => {
      pushNotification(notifications.draftSaved, this.context.addNotification)
    })
  }

  deleteDraft = (id) => {
    const options = {
      method: 'DELETE',
    }

    return apiRequest(`drafts/${id}`, options).then(() => {
      this.getDrafts()
      this.clearForm()
      pushNotification(notifications.draftDeleted, this.context.addNotification)
    })
  }

  recalculateValues = (values) => {
    const recalcValues = {
      ...values,
      ...this.recalculateContractValues(values),
      subcontracts: values.subcontracts.map((sc) => ({
        ...sc,
        ...this.recalculateSubcontractValues(sc),
      })),
    }
    return recalcValues
  }

  recalculateContractValues = (values) => ({
    ...values,
    fteEquivalent: getFteEquivalent(values),
    salaryEur: getSalaryEur(values),
    superGrossSalary: getSuperGrossSalary(values),
  })

  recalculateSubcontractValues = (values) => ({
    ...values,
    salaryEur: getSalaryEur(values),
    superGrossSalary: getSuperGrossSalary(values),
  })

  loadDraft = (draftId) => {
    this.clearForm()
    if (draftId) {
      const { values, obsoleteFields } = formatValues(
        this.state.drafts[Number(draftId)].fields,
      )
      if (obsoleteFields.length > 0) {
        const formattedObsoleteFields = obsoleteFields.join(', ')
        pushNotification(
          notifications.draftLoadError,
          this.context.addNotification,
          formattedObsoleteFields,
        )
      }

      const defaultValues = this.getDefaultFormState().values
      this.setState({
        values: this.recalculateValues({ ...defaultValues, ...values }),
      })
      this.updateCategories(values.legalForm, values.employer)
    }
  }

  handleChange = (e) => {
    const { name } = e.target
    const { updating, values } = this.state
    const { value } = e.target

    const newValues = { ...this.state.values, [name]: value }

    if (name === 'legalForm') {
      if (isFreelancing(value)) {
        newValues.employer = null
      } else {
        newValues.companyId = null
      }
    }

    this.setState({
      values: this.recalculateContractValues(newValues),
    })

    if (updating && name === 'validFrom') {
      this.reloadData(value)
    }
    if (name === 'legalForm') {
      this.updateCategories(value)
    }
    if (name === 'employer') {
      this.updateCategories(newValues.legalForm, value)
    }
  }

  handleSubcontractChange = (e) => {
    const { name, value } = e.target

    const [subcontractIndex, subcontractFieldName] = name.split('-')

    const newValues = {
      ...this.state.values.subcontracts[subcontractIndex],
      [subcontractFieldName]: value,
    }

    if (subcontractFieldName === 'legalForm') {
      if (isFreelancing(value)) {
        newValues.employer = null
      } else {
        newValues.companyId = null
      }
    }

    this.setState(
      set(this.state.values, ['subcontracts', subcontractIndex], {
        ...this.recalculateSubcontractValues(newValues),
      }),
    )

    if (subcontractFieldName === 'legalForm') {
      this.updateCategories(this.state.values.legalForm)
    }
  }

  handleAddSubcontract = () => {
    this.setState({
      values: {
        ...this.state.values,
        subcontracts: this.state.values.subcontracts.concat([
          {
            subcontractType: subcontractTypes.payment.key,
            salaryBonus: 0,
            currency: this.state.values.currency,
          },
        ]),
      },
    })
  }

  handleDropSubcontract = (index) => {
    this.setState({
      values: {
        ...this.state.values,
        subcontracts: this.state.values.subcontracts.filter(
          (item, i) => i !== index,
        ),
      },
    })
  }

  getEmployeeCompanies = (employeeId) => {
    apiRequest(`employees/${employeeId}/companies`)
      .then((companies) => {
        const companyOptions = companies.map(({ id, legalName, isActive }) => ({
          label: legalName,
          key: id,
          inactive: !isActive,
        }))
        this.setState({ companyOptions })
      })
      .catch((err) => handleUncaughtError(err, this.context.addNotification))
  }

  upsertEmployee = (data, onResponse, contractsToDelete, fieldsToChange) => {
    const options = {
      method: 'POST',
      body: { updates: [{ data, contractsToDelete, fieldsToChange }] },
      ignoreAuthError: true,
    }

    apiRequest('employees', options)
      .then(onResponse)
      .catch((err) => {
        if (err === sessionExpired) {
          this.setState({ sessionExpired: true })
        } else {
          handleUncaughtError(err, this.context.addNotification)
        }
      })
  }

  parseValues = (values, ignoreNull = false) => {
    const data = {}

    const isSomeLegalFormEmployment = isSomeEmployeeLegalFormEmployment(values)
    const isSomeLegalFormNotEmployment =
      isSomeEmployeeLegalFormNotEmployment(values)

    let fields = [...nonEmploymentFieldsEditable, ...employeeFieldsEditable]

    if (isSomeLegalFormEmployment && !isSomeLegalFormNotEmployment) {
      fields = employmentFieldsEditable
    }
    if (!isSomeLegalFormEmployment && isSomeLegalFormNotEmployment) {
      fields = nonEmploymentFieldsEditable
    }

    // process employee, main contract and legalForm type editable fields
    fields.forEach(({ name, parse }) => {
      const value = trimSpaces(values[name])
      if (!ignoreNull || value != null) {
        data[name] = parse ? parse(value) : value
      }
    })

    // parse subcontract and their fields
    data.subcontracts = values.subcontracts.map((subcontract) => {
      const parsedSubcontract = {}
      getSubcontractFields(subcontract, true).forEach(({ name, parse }) => {
        const value = trimSpaces(subcontract[name])
        if (!ignoreNull || value != null) {
          parsedSubcontract[name] = parse ? parse(value) : value
        }
      })

      return parsedSubcontract
    })

    return data
  }

  handleCreate = (onCreate) => (e) => {
    const {
      state: { values },
      props: { onSave },
    } = this
    const data = this.parseValues(values)
    data.startingDate = data.validFrom

    this.upsertEmployee(data, ({ updated: [ids] }) => {
      pushNotification(
        notifications.employeeCreated,
        this.context.addNotification,
      )
      onSave(
        !legalForms[data.legalForm].isEmployment,
        ids.employeeId,
        ids.contractId,
      )
      onCreate()
    })
  }

  handleUpdate = (onUpdate) => (e) => {
    const {
      state: { values, companyOptions },
      props: { employee, onSave },
    } = this

    prepareUpdate(values, employee, companyOptions).then(
      ({ data, conflictChanges, fieldsToChange }) => {
        if (conflictChanges.needManualResolution) {
          this.setState({
            confirmUpdate: {
              show: true,
              data,
              onUpdate,
              conflictChanges,
              fieldsToChange,
            },
          })
        } else {
          this.upsertEmployee(
            data,
            () => {
              pushNotification(
                notifications.employeeUpdated,
                this.context.addNotification,
              )
              onSave()
              onUpdate()
            },
            undefined,
            fieldsToChange,
          )
        }
      },
    )
  }

  closeConfirmDialog = () => {
    this.setState({
      confirmUpdate: { ...this.state.confirmUpdate, show: false },
    })
  }

  closeExpiredDialog = () => {
    this.setState({ sessionExpired: false })
  }

  handleUpdateConfirm = (fieldsToChange, deleteContracts) => {
    const {
      state: {
        confirmUpdate: { data, onUpdate },
      },
      props: { onSave },
    } = this
    this.upsertEmployee(
      data,
      () => {
        pushNotification(
          notifications.employeeUpdated,
          this.context.addNotification,
        )
        onSave()
        onUpdate()
      },
      Object.keys(deleteContracts),
      fieldsToChange,
    )
    this.setState({
      confirmUpdate: { ...this.state.confirmUpdate, show: false },
    })
  }

  getCategories = (legalForm, employer, subcontracts) => {
    if (!legalForm) {
      return [...baseCategories]
    }

    const actualSubcontracts = subcontracts || this.state.values.subcontracts

    const areSelectedLegalFormsEmploymentsArray = [
      legalForms[legalForm].isEmployment,
      ...(actualSubcontracts.length
        ? actualSubcontracts
            .filter((subcontract) => subcontract.legalForm)
            .map(
              (subcontract) => legalForms[subcontract.legalForm].isEmployment,
            )
        : []),
    ]

    const isSomeEmployment = areSelectedLegalFormsEmploymentsArray.some(Boolean)
    const isSomeNotEmployment =
      !areSelectedLegalFormsEmploymentsArray.every(Boolean)

    const shouldDisplayHungarianFields = (f) =>
      ![
        'healthInsuranceNumber',
        'motherName',
        'taxId',
        'illnessTaxExemption',
        'familyTaxExemption',
        'otherAllowances',
        'paysChildCareFee',
      ].includes(f.name) || ['vlhu', 'spotHu'].includes(employer)
    const filteredEmploymentDetailsFields = employmentDetailsFields.filter(
      (f) => shouldDisplayHungarianFields(f),
    )
    if (isSomeEmployment && isSomeNotEmployment) {
      return [
        ...baseCategories,
        [...companyOnlyDetailsFields, ...filteredEmploymentDetailsFields],
      ]
    }

    if (isSomeEmployment) {
      return [...baseCategories, filteredEmploymentDetailsFields]
    }

    return [...baseCategories, companyDetailsFields]
  }

  updateCategories = (legalForm, employer, subcontracts) => {
    this.setState({
      showCategories: this.getCategories(legalForm, employer, subcontracts),
    })
  }

  getContractChangesInfo = () => {
    const {
      state: { values, updating, contractDoesNotExist },
      props: { employee },
    } = this
    const changes = []

    if (updating) {
      const originalContract = contractDoesNotExist
        ? employee.nextContract
        : employee

      contractFields
        .concat(employmentDetailsFields)
        .concat(companyOnlyDetailsFields)
        .forEach(({ name, parse, formControl: { readOnly } = {} }) => {
          if (readOnly) {
            return
          }

          const value = parse ? parse(values[name]) : values[name]
          const originalValue = parse
            ? parse(originalContract[name])
            : originalContract[name]

          if (value !== originalValue) {
            changes.push(name)
          }
        })

      if (values.subcontracts.length) {
        values.subcontracts.forEach((subcontract, index) => {
          getSubcontractFields(subcontract, true).forEach(
            ({ name, parse, formControl: { readOnly } = {} }) => {
              if (readOnly) {
                return
              }

              const value = parse ? parse(subcontract[name]) : subcontract[name]
              const originalSubcontract = find(originalContract.subcontracts, [
                'contractId',
                subcontract.contractId,
              ])

              if (!originalSubcontract) {
                changes.push(`${index}-${name}`)
                return
              }

              const originalValue = parse
                ? parse(originalSubcontract[name])
                : originalSubcontract[name]

              if (value !== originalValue) {
                changes.push(`${index}-${name}`)
              }
            },
          )
        })
      }
      if (values.subcontracts?.length < originalContract.subcontracts?.length) {
        changes.push(`subcontractDeleted`)
      }
    }

    return {
      changed: updating && (contractDoesNotExist || changes.length > 0),
      validFromChanged: changes.includes('validFrom'),
      validFromOnly: changes.length === 1 && changes.includes('validFrom'),
    }
  }

  employmentDetailsChanged = () => {
    const {
      state: { values, updating, showCategories },
      props: { employee },
    } = this

    if (!showCategories.includes(employmentDetailsFields)) {
      // don't check at all, some values are prefilled
      return false
    }

    if (updating) {
      return employmentDetailsFields.some(({ name, parse }) => {
        const value = parse ? parse(values[name]) : values[name]
        const originalValue = parse ? parse(employee[name]) : employee[name]

        return value !== originalValue
      })
    } else {
      return false
    }
  }

  companyDetailsChanged = () => {
    const {
      state: { values, updating },
      props: { employee },
    } = this
    if (updating) {
      return companyDetailsFields.some(({ name, parse }) => {
        const value = parse ? parse(values[name]) : values[name]
        const originalValue = parse ? parse(employee[name]) : employee[name]
        return value !== originalValue
      })
    } else {
      return false
    }
  }

  render() {
    const { show, onCancel, employee, employees } = this.props
    const {
      values,
      updating,
      confirmUpdate,
      sessionExpired,
      contractDoesNotExist,
      showCategories,
      companyOptions,
      drafts,
    } = this.state
    const showConfirm = confirmUpdate ? confirmUpdate.show : false
    const { closeConfirmDialog, handleUpdateConfirm } = this

    if (sessionExpired) {
      return (
        <SessionExpiredDialog show={show} onCancel={this.closeExpiredDialog} />
      )
    } else if (showConfirm) {
      return (
        <ContractConflictEditor
          show={show}
          validFrom={values.validFrom}
          closeConfirmDialog={closeConfirmDialog}
          handleUpdateConfirm={handleUpdateConfirm}
          conflictChanges={confirmUpdate.conflictChanges}
          fieldsToChange={confirmUpdate.fieldsToChange}
        />
      )
    } else {
      return (
        <FormWrapper
          values={values}
          employee={employee}
          employees={employees}
          drafts={drafts}
          show={show}
          contractDoesNotExist={contractDoesNotExist}
          contractChangesInfo={this.getContractChangesInfo()}
          employmentDetailsChanged={this.employmentDetailsChanged()}
          companyDetailsChanged={this.companyDetailsChanged()}
          showCategories={showCategories}
          handleAddSubcontract={this.handleAddSubcontract}
          handleDropSubcontract={this.handleDropSubcontract}
          updating={updating}
          onCancel={onCancel}
          onChange={this.handleChange}
          onSubcontractChange={this.handleSubcontractChange}
          onSubmit={updating ? this.handleUpdate : this.handleCreate}
          companyOptions={companyOptions}
          upsertDraft={this.upsertDraft}
          deleteDraft={this.deleteDraft}
          loadDraft={this.loadDraft}
        />
      )
    }
  }
}

class _FormWrapper extends Component {
  state = {
    selectedDraft: '',
  }

  resetValidations = () => this.props.$fieldEvent('reset')

  handleCancel = () => {
    this.props.onCancel()
    this.setState({ selectedDraft: '' })
    this.resetValidations()
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.$submit(
      this.props.onSubmit(() => {
        this.resetValidations()
        if (this.state.selectedDraft) {
          this.handleDeleteDraft()
        }
      }),
    )
  }

  handleSaveDraft = () => {
    const { selectedDraft } = this.state

    this.props.upsertDraft(selectedDraft).then(this.handleCancel)
  }

  handleDeleteDraft = () => {
    const { selectedDraft } = this.state

    if (selectedDraft) {
      this.props.deleteDraft(selectedDraft).then(this.resetValidations)
      this.setState({ selectedDraft: '' })
    }
  }

  handleSelectDraft = (e) => {
    const { value } = e.target

    this.props.loadDraft(value)
    this.setState({ selectedDraft: value })
    this.resetValidations()
  }

  personalDataChanged = () => {
    const { values, employee, contractDoesNotExist } = this.props

    if (contractDoesNotExist) {
      // allows to extend contract without any other changes
      return true
    }

    return personalDetailsFields.some(
      ({ name, parse, formControl: { readOnly } = {} }) => {
        if (readOnly) {
          return false
        }

        const value = parse ? parse(values[name]) : values[name]
        const originalValue = parse ? parse(employee[name]) : employee[name]

        return value !== originalValue
      },
    )
  }

  handleDropSubcontract = (index) => {
    this.props.handleDropSubcontract(index)
    this.resetValidations()
  }

  isDisabled = (field) => {
    const { name, contractField } = field
    const {
      updating,
      values: { isActive, department, taxBonusOnChildren },
      contractChangesInfo,
      employmentDetailsChanged,
      companyDetailsChanged,
    } = this.props

    // new employee has to be active
    if (name === 'isActive') {
      return !updating
    }

    if (name === 'comment') {
      return (
        !contractChangesInfo.changed &&
        !employmentDetailsChanged &&
        !companyDetailsChanged
      )
    }

    if (name === 'reasonForLeaving' || name === 'departure') {
      return !this.props.values[name] && isActive === statuses.active.key
    }

    if (name === 'taxBonusNumberOfChildren') {
      return taxBonusOnChildren !== 'true'
    }

    if (contractField && name !== 'validFrom' && name !== 'note') {
      return isActive === statuses.inactive.key
    }

    if (name === 'msDynamicsId') {
      return true
    }

    return false
  }

  valueOrEmptyString = (value) =>
    isNumber(value) || isString(value) || moment.isMoment(value) ? value : ''

  getTitleAndAction = () => {
    const { employee, updating, contractChangesInfo } = this.props
    const name = getFullName(employee)
    const result = {}
    if (!updating) {
      result.title = 'New Employee'
      result.action = 'Create'
    } else if (contractChangesInfo.validFromOnly) {
      result.title = `Move Current ${name}'s Contract`
      result.action = 'Move'
    } else if (
      contractChangesInfo.changed &&
      !contractChangesInfo.validFromChanged
    ) {
      result.title = `Update Current ${name}'s Contract`
      result.action = 'Update'
    } else if (
      contractChangesInfo.validFromChanged &&
      !contractChangesInfo.validFromOnly
    ) {
      result.title = `Create New Contract For ${name}`
      result.action = 'Create'
    } else {
      result.title = `${name}'s Contract`
      result.action = 'Update'
    }

    return result
  }

  getCompaniesSearchLink = () => {
    const { employee } = this.props
    const queryString = qs.stringify(
      { search: getFullNameReversed(employee) },
      { addQueryPrefix: true },
    )
    return (
      <a
        className={styles.linkToCompanies}
        href={`/companies${queryString}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Glyphicon glyph="new-window" />
      </a>
    )
  }

  renderDraftButtons = () => {
    const options = this.props.drafts || {}
    const { selectedDraft } = this.state

    return (
      <Form inline className={styles.draftsForm}>
        <FormGroup>
          <Field
            key="drafts"
            name="drafts"
            formControl={{
              componentClass: 'select',
            }}
            hideLabel
            value={selectedDraft}
            onChange={this.handleSelectDraft}
            options={options}
            defaultOption={{
              key: '',
              label: `Select Draft (${Object.keys(options).length})`,
            }}
          />
        </FormGroup>
        &nbsp;
        <FormGroup>
          <Button
            className={styles.deleteButton}
            key="deleteDraft"
            bsStyle="danger"
            onClick={this.handleDeleteDraft}
            disabled={!selectedDraft}
          >
            <Glyphicon glyph="trash" />
          </Button>
        </FormGroup>
      </Form>
    )
  }

  render() {
    const {
      values,
      show,
      updating,
      onChange,
      onSubcontractChange,
      $field,
      $validation,
      contractDoesNotExist,
      employee,
      employees,
      showCategories,
      companyOptions,
      handleAddSubcontract,
      contractChangesInfo,
    } = this.props

    const { handleSubmit, handleCancel, personalDataChanged } = this
    const { validFrom: originalValidFrom } =
      (contractDoesNotExist ? employee.nextContract : employee) || {}
    const { title, action } = this.getTitleAndAction()
    const canSaveAsDraft =
      values.firstName &&
      values.firstName.length > 0 &&
      values.lastName &&
      values.lastName.length > 0

    const companiesSearchLink = this.getCompaniesSearchLink()

    return (
      <Modal show={show} bsSize="large" onHide={this.handleCancel}>
        <Modal.Header>
          <Modal.Title data-test="modalTitle" className={styles.title}>
            {title}
            {!updating && this.renderDraftButtons()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="employeeForm" onSubmit={handleSubmit} horizontal>
            <Row>
              <Col xs={4}>
                <Row>
                  <Col xs={8} xsOffset={4}>
                    <h4>Personal Details</h4>
                  </Col>
                </Row>
              </Col>
              <Col xs={8}>
                <h4 className="text-center">
                  Contract details
                  {updating && (
                    <span>
                      {' '}
                      {contractDoesNotExist && 'will be'} valid from{' '}
                      {moment.utc(originalValidFrom).format(dateFormatHuman)}
                    </span>
                  )}
                </h4>
              </Col>
            </Row>
            <Row>
              {showCategories.map((fields, id) => (
                <Col xs={4} key={id}>
                  {fields.map((field, index) => {
                    if (
                      (!updating && field.hideOnCreate) ||
                      field.viewInEditor === false
                    ) {
                      return null
                    }

                    if (
                      field.name === 'employer' &&
                      isFreelancing(values.legalForm)
                    ) {
                      return null
                    }

                    let options = field.options
                    let link
                    if (field.name === 'companyId') {
                      options = companyOptions
                      link = companiesSearchLink
                    } else if (field.name === 'vlCompany') {
                      options = filterVlCompanyOptions(
                        options,
                        values.vlCompany,
                        values.validFrom,
                      )
                    } else if (field.name === 'department') {
                      options = filterDepartmentOptions(
                        options,
                        values.validFrom,
                        values.vlCompany,
                        values.department,
                      )
                    } else if (field.name === 'subdepartment') {
                      options = filterSubdepartmentOptions(
                        options,
                        values.validFrom,
                        values.department,
                        values.subdepartment,
                      )
                    } else if (field.name === 'reportsTo') {
                      options = employees
                        .filter((emp) => emp.isActive && emp.id !== employee.id)
                        .map((e) => ({
                          label: e.vacuumId,
                        }))
                      options.push({ label: 'Nobody' })
                    }

                    const validationState = $validation[field.name]
                    if (
                      ['vlCompany', 'department', 'subdepartment'].includes(
                        field.name,
                      )
                    ) {
                      validationState.show = true
                    }
                    const tooltip =
                      field.name === 'emailOld1'
                        ? 'The Old Email fields are for people who have changed emails with their accounts on various services.'
                        : ''

                    return (
                      <Field
                        {...field}
                        key={index}
                        labelLength={4}
                        valueLength={8}
                        disabled={this.isDisabled(field)}
                        options={options}
                        value={this.valueOrEmptyString(values[field.name])}
                        tooltip={tooltip}
                        validationState={$validation[field.name]}
                        units={field.units && field.units(values)}
                        required={
                          field.required
                            ? field.required(values)
                            : !!$validation[field.name]
                        }
                        link={link}
                        {...$field(field.name, onChange)}
                      />
                    )
                  })}
                </Col>
              ))}
            </Row>
            {values.subcontracts && (
              <EmployeeSubcontractsEditor
                employees={employees}
                values={values}
                validation={$validation}
                field={$field}
                valueOrEmptyString={this.valueOrEmptyString}
                handleDropSubcontract={this.handleDropSubcontract}
                onChange={onSubcontractChange}
                companyOptions={companyOptions}
                companiesSearchLink={companiesSearchLink}
              />
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="pull-right">
            <Button onClick={handleCancel} data-test="closeButton">
              Cancel
            </Button>
            <Button onClick={handleAddSubcontract}>Add subcontract</Button>
            {!updating && (
              <Button onClick={this.handleSaveDraft} disabled={!canSaveAsDraft}>
                {this.state.selectedDraft ? 'Update Draft' : 'Save as Draft'}
              </Button>
            )}
            <Button
              disabled={
                updating &&
                !personalDataChanged() &&
                !contractChangesInfo.changed
              }
              type="submit"
              form="employeeForm"
              bsStyle="primary"
              data-test="createButton"
            >
              {action}
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}

const FormWrapper = validated(validationConfig)(_FormWrapper)

function validationConfig(props) {
  const {
    values,
    employee,
    updating,
    contractChangesInfo,
    showCategories,
    employmentDetailsChanged,
  } = props
  const fields = []
  const validations = {}

  showCategories.forEach((category) => {
    category
      .filter((field) => field.validation && !(!updating && field.hideOnCreate))
      .forEach((field) => {
        fields.push(field.name)
        validations[field.name] = field.validation(
          { ...values, subcontracts: [...values.subcontracts] },
          employee[field.name],
          contractChangesInfo.changed || employmentDetailsChanged,
        )
      })
  })

  if (values.subcontracts) {
    values.subcontracts.forEach((subcontract, subcontractIndex) => {
      getSubcontractFields(subcontract)
        .filter(
          (field) => field.validation && !(!updating && field.hideOnCreate),
        )
        .forEach((field) => {
          fields.push(`${subcontractIndex}-${field.name}`)
          validations[`${subcontractIndex}-${field.name}`] = field.validation({
            ...values.subcontracts[subcontractIndex],
          })
        })
    })
  }

  return {
    fields,
    validations,
  }
}
