import moment from 'moment'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { friendlyFormatIBAN } from 'ibantools'
import { get, isNil, isString } from 'lodash'
import {
  salaryForms,
  booleanOptions,
  legalForms,
  contractTypes,
  currencyValues,
  vlCompanies,
} from '../common/enums'
import { dateFormat, dateFormatHuman } from './ui'
import { tppTax, bpsTax } from '../common/constants'
import { getCurrencyRate } from './network'

export const resolveDateField =
  (field) =>
  ({ [field]: value }, { exportable } = {}) => {
    const format = exportable ? dateFormat : dateFormatHuman
    return value != null && moment.utc(value).isValid()
      ? moment.utc(value).format(format)
      : ''
  }

export const resolveMoneyField =
  (field) =>
  ({ [field]: value }, { exportable } = {}) =>
    Number(value).toFixed(2)

export const getBooleanFieldValue =
  (field) =>
  ({ [field]: value }, { exportable } = {}) => {
    if (exportable) {
      return value == null ? '' : value ? 1 : 0
    } else {
      return value == null
        ? ''
        : value
        ? booleanOptions.true.label
        : booleanOptions.false.label
    }
  }

export const parseDateField = (value) => {
  if (value === undefined) {
    return null
  } else {
    value = moment.isMoment(value) ? value : moment.utc(value)
    return value.isValid() ? value.format(dateFormat) : null
  }
}

export const parseBooleanField = (value) => {
  if (value == null) {
    return null
  }
  return typeof value === 'boolean' ? value : booleanOptions[value].value
}

export const parseNumberField = (value) =>
  value == null ? null : Number(value)

export const parseNullableString = (value) => value || null

export const parseMultiIdSelect = (value) =>
  Array.isArray(value) ? value : !value ? [] : value.split(',').map(Number)

export const getFullName = ({ firstName, lastName }) =>
  [firstName, lastName].join(' ')

export const getFullNameReversed = ({ firstName, lastName }) =>
  [lastName, firstName].join(' ')

export const getFteEquivalent = (
  { salaryForm, fte, salary, salaryBonus, currency },
  { date } = { date: moment() },
) => {
  if (salaryForm && fte && fte !== '0' && salary) {
    const salaryEur = getSalaryEur({ salary, currency }, { date })
    if (!(salaryEur === 0 || !!Number(salaryEur))) {
      return 'FOREX Unavailable'
    }
    const salaryWithBonus = salaryEur * (1 + salaryBonus / 100)
    if (salaryForm === salaryForms.pph.key) {
      return (salaryWithBonus * 145).toFixed(2)
    } else {
      return (salaryWithBonus / (fte / 100)).toFixed(2)
    }
  }

  return undefined
}

export const getSalaryEur = (
  { salary, currency = 'EUR' },
  { date } = { date: moment() },
) => {
  const currencyRate = getCurrencyRate(currency, date)
  if (!currencyRate) {
    return 'FOREX Unavailable'
  }
  return (salary / currencyRate).toFixed(2)
}

export const getSalaryUnits =
  (originalCurrency) =>
  ({ salaryForm, currency = 'EUR' }) => {
    const symbol = originalCurrency
      ? currencyValues[currency].symbol
      : currencyValues.EUR.symbol
    if (!salaryForm) {
      return symbol
    }
    return salaryForm === salaryForms.pph.key ? `${symbol}/h` : `${symbol}/m`
  }

export const nonRequiredField = () => false

export const requiredIfOtherFieldPresent = (field) =>
  field != null && field !== ''

export const requiredFieldOnlyForEmployment = ({ legalForm }) =>
  legalForm ? legalForms[legalForm].isEmployment : false // TODO: hot fix - necessary to consider also legal forms from subcontracts

export const requiredFieldOnlyForInternalContracts = ({ contractType }) =>
  contractType ? contractTypes[contractType].internalContract : false

export const requiredFieldExceptSomeVlCompanies = (
  { vlCompany },
  exceptedCompanies,
) => (vlCompany ? !exceptedCompanies.includes(vlCompany) : false)

export const getSuperGrossSalary = (
  { legalForm, salary, currency },
  { date } = { date: moment() },
) => {
  if (isNaN(salary) || salary === '') {
    return ''
  }

  const salaryEur = getSalaryEur({ salary, currency }, { date })
  if (!(salaryEur === 0 || !!Number(salaryEur))) {
    return 'FOREX Unavailable'
  }

  if (legalForm === legalForms.student.key) {
    return ((1 + bpsTax) * salaryEur).toFixed(2)
  } else if (
    legalForm === legalForms.employee.key ||
    legalForm === legalForms.oneTimeWork.key ||
    legalForm === legalForms.serviceAgreement.key
  ) {
    return ((1 + tppTax) * salaryEur).toFixed(2)
  } else {
    return parseFloat(salaryEur).toFixed(2)
  }
}

export const renderPhoneNumber = (phoneNumber) =>
  phoneNumber && parsePhoneNumberFromString(phoneNumber).formatInternational()

export const renderIBAN = (iban) =>
  isNil(iban) ? iban : friendlyFormatIBAN(iban)

export const trimSpaces = (value) => (isString(value) ? value.trim() : value)

export const resolveUTCDate =
  (field) =>
  ({ [field]: value }, { exportable } = {}) => {
    const format = exportable ? dateFormat : dateFormatHuman
    return value != null && moment(value).isValid()
      ? moment(value).format(format)
      : ''
  }

export const getFiltersFromUrl = (query, fields, def = []) =>
  fields.reduce((acc, val) => {
    if (val.filter) {
      acc[val.name] =
        query[val.name] !== undefined
          ? query[val.name]
              .split(',')
              .map((value) => get(val.options, [value, 'key'], undefined))
              .filter(Boolean)
          : def
    }
    return acc
  }, {})

export const filtersToUrl = (filters) => {
  const formattedFilters = {}
  Object.entries(filters).forEach(([field, filter]) => {
    if (filter != null) {
      formattedFilters[field] = filter.join(',')
    }
  })
  return formattedFilters
}

const areEmployeeLegalFormsEmploymentArray = (values) => [
  values.legalForm && legalForms[values.legalForm].isEmployment,
  ...values.subcontracts.map(
    (s) => s.legalForm && legalForms[s.legalForm].isEmployment,
  ),
]

export const isSomeEmployeeLegalFormEmployment = (values) =>
  areEmployeeLegalFormsEmploymentArray(values).some(Boolean)

export const isSomeEmployeeLegalFormNotEmployment = (values) =>
  !areEmployeeLegalFormsEmploymentArray(values).every(Boolean)

export const isEmpty = (object) =>
  Object.values(object).every((x) => x === null || x === '')

export const isChanged = (object) =>
  Object.values(object).some((x) => x.changed === true)
