import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { get } from 'lodash'
import moment from 'moment'
import Loading from './components/Loading'
import SimpleModal from './components/SimpleModal'
import { salaryInfoFields } from './employeeFields'
import ConfirmDialog from './components/ConfirmDialog'
import {
  dateFormatHuman,
  getFullName,
  getContractsAll,
  apiRequest,
  handleUncaughtError,
  getConfig,
  pushNotification,
  notifications,
} from './utils'
import styles from './SalaryContractsInfo.module.scss'
import HorizontalTimelineContracts from './components/HorizontalTimelineContracts'
import NotificationContext from 'utils/context/NotificationContext'

const Record = ({ record, recordFields }) => {
  const { isActive } = record
  const rowClassName = !isActive ? styles.inactive : ''

  return (
    <tr className={rowClassName}>
      {recordFields.map(
        ({ name, options, resolve, render, formControl, units }) => {
          let value
          if (render) {
            value = render(record)
          } else if (resolve) {
            value = resolve(record)
          } else {
            value = record[name]
            if (options) {
              value = get(options, [value, 'label'], value)
            }
          }
          const className =
            formControl && formControl.type === 'number' ? styles.number : ''

          return isActive || name === 'validFrom' || name === 'validTo' ? (
            <td key={name} className={className}>
              {value}
              {(value === 0 || !!Number(value)) && units && !render && (
                <span>&nbsp;{units(record)}</span>
              )}
            </td>
          ) : null
        },
      )}
      {!isActive && (
        <td className={styles.inactiveMsg} colSpan={recordFields.length - 2}>
          Inactive
        </td>
      )}
    </tr>
  )
}

export default class SalaryContractsInfo extends Component {
  state = {
    contractToDelete: null,
    employee: null,
  }

  static propTypes = {
    modal: PropTypes.bool.isRequired,
    show: PropTypes.bool.isRequired,
    employeeId: PropTypes.number,
    showSalary: PropTypes.bool,
    getEmployeeById: PropTypes.func.isRequired,
    updateUrl: PropTypes.func,
    onChange: PropTypes.func,
    onCancel: PropTypes.func,
    onContractSelect: PropTypes.func.isRequired,
    selectedContractDate: PropTypes.object,
    date: PropTypes.object.isRequired,
  }

  static contextType = NotificationContext

  componentDidMount() {
    this.update({}, this.props)
  }

  componentDidUpdate(prevProps) {
    this.update(prevProps, this.props)
  }

  componentWillUnmount() {
    this.clearPolling()
  }

  update(props, nextProps) {
    const { show: oldShow, employeeId: oldEmployeeId } = props
    const { show, employeeId, getEmployeeById } = nextProps

    if (show) {
      if (!oldShow || employeeId !== oldEmployeeId) {
        this.loadData(employeeId)
        getEmployeeById(employeeId).then((employee) =>
          this.setState({ employee }),
        )
      }
      this.setPolling()
    } else {
      this.clearPolling()
    }
  }

  setPolling = () => {
    if (!this.polling) {
      this.polling = window.setInterval(this.loadData, getConfig().polling)
    }
  }

  clearPolling = () => {
    if (this.polling) {
      window.clearInterval(this.polling)
      this.polling = null
    }
  }

  loadData = (employeeId = this.props.employeeId) => {
    this.getSalaries(employeeId)
    this.getContracts(employeeId)
  }

  handleSwitchScreen = () => {
    const { updateUrl } = this.props
    const newTimeline = !this.props.showSalary ? 'false' : 'true'
    localStorage.setItem('contractTimeline', newTimeline)
    updateUrl()
  }

  getContracts = (employeeId = this.props.employeeId) => {
    if (!employeeId) {
      return
    }

    getContractsAll(employeeId, this.props.date)
      .then((contracts) => {
        this.setState({ contractRecords: contracts })
      })
      .catch((err) => handleUncaughtError(err, this.context.addNotification))
  }

  getSalaries = (employeeId = this.props.employeeId) => {
    if (!employeeId) {
      return
    }

    apiRequest(`employees/${employeeId}/salaries`)
      .then((salaries) => {
        const mergedSalaries = []
        const ignoreFields = ['validTo', 'validFrom', 'mainContractId']

        // currently first salary in transitive chain
        let startSalary = salaries.length ? salaries[0] : null

        for (let i = 1; i < salaries.length; i++) {
          let merge = true

          // check if all keys except 'ignoreFields' match
          for (const key of Object.keys(salaries[i])) {
            if (
              startSalary[key] !== salaries[i][key] &&
              !ignoreFields.includes(key)
            ) {
              merge = false
              break
            }
          }

          // add to merge salaries or continue in transitive chain
          if (!merge) {
            mergedSalaries.push({
              ...startSalary,
              isSubcontract: !!startSalary.mainContractId,
            })
            startSalary = salaries[i]
          }
        }
        mergedSalaries.push({
          ...startSalary,
          isSubcontract: !!startSalary.mainContractId,
        })

        let validTo = null
        for (let i = mergedSalaries.length - 1; i >= 0; i--) {
          mergedSalaries[i].validTo = validTo
          if (
            i > 0 &&
            mergedSalaries[i].validFrom !== mergedSalaries[i - 1].validFrom
          ) {
            validTo = moment(mergedSalaries[i].validFrom).subtract(1, 'days')
          }
        }

        this.setState({ salaryRecords: mergedSalaries.reverse() })
      })
      .catch((err) => handleUncaughtError(err, this.context.addNotification))
  }

  renderRecords = () => {
    const { salaryRecords, contractRecords } = this.state
    const { showSalary, onContractSelect, selectedContractDate, date } =
      this.props
    const showDelete =
      contractRecords && contractRecords.length >= 2 && getConfig().writeAccess
    const records = showSalary ? salaryRecords : contractRecords

    if (!records) {
      return <Loading />
    }

    return showSalary ? (
      <Table bordered className={styles.modalTable}>
        <thead>
          <tr>
            {salaryInfoFields.map(({ name, label }) => (
              <th key={name}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {records.map((record, index) => (
            <Record
              key={`${record.validFrom}-${index}`}
              record={record}
              recordFields={salaryInfoFields}
            />
          ))}
        </tbody>
      </Table>
    ) : (
      <HorizontalTimelineContracts
        records={records.slice().reverse()}
        employee={this.state.employee}
        showDelete={showDelete}
        onDelete={this.onDelete}
        onSelect={onContractSelect}
        selectedContractDate={selectedContractDate}
        date={date}
      />
    )
  }

  onDelete = (contract) => {
    this.setState({ contractToDelete: contract })
  }

  closeConfirmDialog = () => {
    this.setState({ contractToDelete: null })
  }

  handleConfirmDelete = () => {
    const { employeeId } = this.props
    const options = {
      method: 'DELETE',
    }
    const contractId = this.state.contractToDelete.id
    apiRequest(`employees/${employeeId}/contracts/${contractId}`, options).then(
      (response) => {
        pushNotification(
          notifications.contractDeleted,
          this.context.addNotification,
        )
        const contractRecords = this.state.contractRecords.filter(
          (c) => c.id !== contractId,
        )
        this.setState({ contractToDelete: null, contractRecords })
        this.loadData()
        this.props.onChange()
      },
    )
  }

  renderSwitchButton = () => {
    const { showSalary } = this.props
    return (
      <Button
        bsStyle="primary"
        onClick={this.handleSwitchScreen}
        data-test="salaryToggle"
      >
        {showSalary ? 'Show Contracts' : 'Show Salary'}
      </Button>
    )
  }

  confirmBody = () => {
    const { employee } = this.state
    const employeeName = employee ? getFullName(employee) : ''
    const validFrom = moment(this.state.contractToDelete.validFrom).format(
      dateFormatHuman,
    )

    return (
      <p>
        Do you really wish to delete contract valid from{' '}
        <strong>{validFrom} </strong>
        for employee <strong>{employeeName}</strong>?
      </p>
    )
  }

  render() {
    const { modal, show, onCancel, showSalary } = this.props
    const { contractToDelete, employee } = this.state

    const employeeName = employee ? getFullName(employee) : ''
    const title = `${employeeName}'s ${showSalary ? 'Salary' : 'Contracts'}`
    if (contractToDelete) {
      return (
        <ConfirmDialog
          onCancel={this.closeConfirmDialog}
          onConfirm={this.handleConfirmDelete}
          body={this.confirmBody()}
          show
        />
      )
    } else if (modal) {
      return (
        <SimpleModal
          show={show}
          large
          title={title}
          titleAddon={this.renderSwitchButton()}
          onCancel={onCancel}
        >
          {this.renderRecords()}
        </SimpleModal>
      )
    } else if (show) {
      return (
        <Col md={12}>
          <div className={styles.userName}>
            <div>
              <h4 data-test="header">{title}</h4>
            </div>
            <div>{this.renderSwitchButton()}</div>
          </div>
          <Row>{this.renderRecords()}</Row>
        </Col>
      )
    } else {
      return null
    }
  }
}
