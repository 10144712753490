import { keys, mapValues, groupBy } from 'lodash'
import { getCodeList } from 'country-list'

export function generateKeys(obj) {
  for (const key of keys(obj)) {
    obj[key].key = key
  }
  return obj
}

export const documentTypes = generateKeys({
  idCard: {
    label: 'ID Card',
  },
  passport: {
    label: 'Passport',
  },
  residencePermitCard: {
    label: 'Residence Permit Card',
  },
})

export const pensionOptions = generateKeys({
  none: {
    label: 'None',
  },
  usualAge: {
    label: 'Usual Age',
  },
  early: {
    label: 'Early',
  },
  widow: {
    label: 'Widow',
  },
  orphan: {
    label: 'Orphan',
  },
  other: {
    label: 'Other',
  },
})

export const permancyOfContractOptions = generateKeys({
  definite: {
    label: 'Definite',
  },
  indefinite: {
    label: 'Indefinite',
  },
})

export const countries = generateKeys(
  mapValues(getCodeList(), (countryName) => ({
    label: countryName,
  })),
)

export const statuses = generateKeys({
  active: {
    value: true,
    label: 'Active',
  },
  inactive: {
    value: false,
    label: 'Inactive',
  },
})

export const booleanOptions = generateKeys({
  true: {
    value: true,
    label: 'Yes',
  },
  false: {
    value: false,
    label: 'No',
  },
})

export const levelOptions = generateKeys(
  [
    0, 9, 10, 11, 11.5, 12, 12.5, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
  ].reduce((all, level) => {
    all[level] = {
      value: level,
      label: level.toString(),
    }
    return all
  }, {}),
)

export const departments = generateKeys({
  it: {
    label: 'Engineering',
    allowedParentVlCompanies: ['vacuumlabs'],
    hasHierarchy: true,
  },
  internalIt: {
    label: 'Internal IT',
  },
  finance: {
    label: 'Finance',
    allowedParentVlCompanies: ['vacuumlabs'],
    hasHierarchy: true,
  },
  reactive: {
    label: 'Reactive',
    excludeFromUiDropdowns: true,
  },
  sales: {
    label: 'Sales',
    hasHierarchy: true,
    allowedParentVlCompanies: ['vacuumlabs'],
  },
  operations: {
    label: 'Operations',
    allowedParentVlCompanies: ['vacuumlabs'],
    hasHierarchy: true,
  },
  hr: {
    label: 'HR',
    allowedParentVlCompanies: ['vacuumlabs'],
    hasHierarchy: true,
  },
  marketing: {
    label: 'Marketing',
    allowedParentVlCompanies: ['vacuumlabs'],
    hasHierarchy: true,
  },
  legal: {
    label: 'Legal',
  },
  recruitment: {
    label: 'Recruitment',
  },
  designProduct: {
    label: 'Product & Design',
    allowedParentVlCompanies: ['vacuumlabs'],
    hasHierarchy: true,
  },
  groupDesignProduct: {
    label: 'Design Pillar',
  },
  tribal: {
    label: 'Tribal',
    excludeFromUiDropdowns: true,
  },
  pm: {
    label: 'Delivery Operations',
    allowedParentVlCompanies: ['vacuumlabs'],
    hasHierarchy: true,
  },
  creative: {
    label: 'Creative',
    excludeFromUiDropdowns: true,
  },
  catering: {
    label: 'Catering',
    excludeFromUiDropdowns: true,
  },
  trama: {
    label: 'Trama',
    excludeFromUiDropdowns: true,
  },
  dataScience: {
    label: 'Data Science',
    allowedParentVlCompanies: ['vacuumlabs'],
    hasHierarchy: true,
  },
  beMoney: {
    label: 'BeMoney',
    excludeFromUiDropdowns: true,
  },
  salesAsia: {
    label: 'Sales Asia',
    excludeFromUiDropdowns: true,
  },
  rcMom: {
    label: 'RC - MOM',
    excludeFromUiDropdowns: true,
  },
  growth: {
    label: 'Growth',
    excludeFromUiDropdowns: true,
  },
  cafe: {
    label: 'Cafe',
    excludeFromUiDropdowns: true,
  },
  sparring: {
    label: 'Sparring',
    excludeFromUiDropdowns: true,
  },
  theSpot: {
    label: 'The Spot',
    excludeFromUiDropdowns: true,
  },
  hhhu: {
    label: 'The Spot HU',
    excludeFromUiDropdowns: true,
  },
  concierge: {
    label: 'Concierge',
    excludeFromUiDropdowns: true,
  },
  daycare: {
    label: 'Daycare',
    excludeFromUiDropdowns: true,
  },
  benefits: {
    label: 'Benefits',
    excludeFromUiDropdowns: true,
  },
  ventures: {
    label: 'Ventures',
  },
  ceo: {
    label: 'Office of CEO',
    allowedParentVlCompanies: ['vacuumlabs'],
    hasHierarchy: true,
    excludeFromUiDropdowns: true,
  },
  nozdormu: {
    label: 'Wincent',
    excludeFromUiDropdowns: true,
  },
  adalite: {
    label: 'NuFi',
    allowedParentVlCompanies: ['adalite'],
    hasHierarchy: true,
  },
  verdikto: {
    label: 'Verdikto',
    excludeFromUiDropdowns: true,
  },
  cfoaas: {
    label: 'Capila',
    excludeFromUiDropdowns: true,
  },
  leadership: {
    label: 'Leadership',
  },
  rcLab: {
    label: 'RC - Lab',
    excludeFromUiDropdowns: true,
  },
  dex: {
    label: 'DEX',
  },
  ksebe: {
    label: 'Ksebe',
    excludeFromUiDropdowns: true,
  },
  csr: {
    label: 'CSR',
    excludeFromUiDropdowns: true,
  },
  robo: {
    label: 'ROBO',
    excludeFromUiDropdowns: true,
  },
  salesAmerica: {
    label: 'Sales America',
    excludeFromUiDropdowns: true,
  },
  salesCyprus: {
    label: 'Sales Cyprus',
    excludeFromUiDropdowns: true,
  },
  space: {
    label: 'Space',
    excludeFromUiDropdowns: true,
  },
  techSupport: {
    label: 'Tech support',
  },
  rcCoreTeam: {
    label: 'RC - core team',
    excludeFromUiDropdowns: true,
  },
  rcInfolinka: {
    label: 'RC - infolinka',
    excludeFromUiDropdowns: true,
  },
  expansionTeam: {
    label: 'Expansion team',
    excludeFromUiDropdowns: true,
  },
  hrGeneral: {
    label: 'HR General',
  },
  hrOperations: {
    label: 'HR Operations',
  },
  benefitManagement: {
    label: 'Benefit Management',
    excludeFromUiDropdowns: true,
  },
  groupRecruitment: {
    label: 'Group Recruitment',
  },
  hrBizPartner: {
    label: 'HR Biz Partner',
  },
  vlPeopleMngmt: {
    label: 'VL People Mngmt',
  },
  blockchainConsulting: {
    label: 'Blockchain Consulting',
    allowedParentVlCompanies: ['vacuumlabs'],
    hasHierarchy: true,
  },
  founder: {
    label: 'Founder',
    excludeFromUiDropdowns: true,
  },
  legalOperations: {
    label: 'Legal Operations',
  },
  delivery: {
    label: 'Delivery',
  },
  commsPillar: {
    label: 'Comms Pillar',
    excludeFromUiDropdowns: true,
  },
  conciergeVL: {
    label: 'Concierge VL',
    excludeFromUiDropdowns: true,
  },
  group: {
    label: 'Group',
    hasHierarchy: true,
    allowedParentVlCompanies: ['group'],
  },
  theSpotBratislava: {
    label: 'The Spot Bratislava',
    hasHierarchy: true,
    allowedParentVlCompanies: ['theSpot'],
    excludeFromUiDropdowns: true,
  },
  theSpotBudapest: {
    label: 'The Spot Budapest',
    hasHierarchy: true,
    allowedParentVlCompanies: ['theSpot'],
    excludeFromUiDropdowns: true,
  },
  generalAdmin: {
    label: 'General Admin',
    hasHierarchy: true,
    allowedParentVlCompanies: ['vacuumlabs'],
  },
  management: {
    label: 'Management',
  },
  nonCore: {
    label: 'Non-core',
  },
  ai: {
    label: 'Artificial Intelligence',
  },
  devOps: {
    label: 'DevOps',
  },
  crypto: {
    label: 'Crypto',
  },
})

export const subdepartments = generateKeys({
  general: {
    label: 'General',
    allowedParentDepartments: [
      'ceo',
      'dataScience',
      'pm',
      'it',
      'operations',
      'sales',
      'adalite',
      'blockchainConsulting',
    ],
  },
  commsPillar: {
    label: 'Comms Pillar',
    allowedParentDepartments: ['group'],
  },
  groupOfficeOfCeo: {
    label: 'Group Office of CEO',
    allowedParentDepartments: ['group'],
  },
  groupProductAndDesign: {
    label: 'Group Product & Design',
    allowedParentDepartments: ['group'],
  },
  leadership: {
    label: 'Leadership',
    allowedParentDepartments: [
      'blockchainConsulting',
      'sales',
      'group',
      'ceo',
      'dataScience',
      'pm',
      'it',
      'finance',
      'hr',
      'marketing',
      'designProduct',
    ],
  },
  talentAquisition: {
    label: 'Talent Acquisition',
    allowedParentDepartments: ['group'],
  },
  cafe: {
    label: 'Cafe',
    allowedParentDepartments: ['theSpotBratislava'],
  },
  catering: {
    label: 'Catering',
    allowedParentDepartments: ['theSpotBratislava'],
  },
  cowork: {
    label: 'Cowork',
    allowedParentDepartments: ['theSpotBratislava', 'theSpotBudapest'],
  },
  daycare: {
    label: 'Daycare',
    allowedParentDepartments: ['theSpotBratislava'],
  },
  techSupport: {
    label: 'Tech Support',
    allowedParentDepartments: ['theSpotBratislava'],
  },
  development: {
    label: 'Development',
    allowedParentDepartments: ['it'],
  },
  devOps: {
    label: 'DevOps',
    allowedParentDepartments: ['it'],
  },
  qa: {
    label: 'Quality Assurance',
    allowedParentDepartments: ['it'],
  },
  controllingAndData: {
    label: 'Controlling & Data',
    allowedParentDepartments: ['finance'],
  },
  projectsAndInvoicing: {
    label: 'Projects & Invoicing',
    allowedParentDepartments: ['finance'],
  },
  taxesAndAccounting: {
    label: 'Taxes & Accounting',
    allowedParentDepartments: ['finance'],
  },
  concierge: {
    label: 'Concierge',
    allowedParentDepartments: ['generalAdmin'],
  },
  iit: {
    label: 'Internal IT',
    allowedParentDepartments: ['generalAdmin'],
  },
  peopleOps: {
    label: 'People Operations',
    allowedParentDepartments: ['hr'],
  },
  peoplePartnering: {
    label: 'People Partnering',
    allowedParentDepartments: ['hr'],
  },
  recruitment: {
    label: 'Recruitment',
    allowedParentDepartments: ['hr'],
  },
  reactiveUniverse: {
    label: 'Reactive Universe',
    allowedParentDepartments: ['marketing'],
  },
  salesMarketing: {
    label: 'Sales Marketing',
    allowedParentDepartments: ['marketing'],
  },
  space: {
    label: 'Space',
    allowedParentDepartments: ['operations'],
  },
  design: {
    label: 'Design',
    allowedParentDepartments: ['designProduct'],
  },
  product: {
    label: 'Product',
    allowedParentDepartments: ['designProduct'],
  },
  peopleExperience: {
    label: 'People Experience',
    allowedParentDepartments: ['hr'],
  },
  management: {
    label: 'Management',
    allowedParentDepartments: ['generalAdmin'],
  },
  hrGeneral: {
    label: 'HR General',
    allowedParentDepartments: ['hr'],
  },
  ventures: {
    label: 'Ventures',
    allowedParentDepartments: [
      'sales',
      'salesAsia',
      'salesCyprus',
      'salesAmerica',
      'ventures',
    ],
  },
  groupDesignProduct: {
    label: 'Design Pillar',
    allowedParentDepartments: ['designProduct', 'groupDesignProduct'],
  },
  adalite: {
    label: 'NuFi',
    allowedParentDepartments: ['adalite'],
  },
  generalLegalCosts: {
    label: 'General Legal Costs',
    allowedParentDepartments: ['legalOperations'],
  },
})

export const vlCompanies = generateKeys({
  group: {
    label: 'Group',
    hasHierarchy: true,
  },
  vacuumlabs: {
    label: 'Vacuumlabs',
    hasHierarchy: true,
  },
  sparring: {
    label: 'Sparring',
  },
  rychlotestCovid: {
    label: 'Rychlotest Covid',
  },
  theSpot: {
    label: 'The Spot',
    hasHierarchy: true,
  },
  trama: {
    label: 'Trama',
  },
  nozdormu: {
    label: 'Wincent',
  },
  adalite: {
    label: 'NuFi',
    hasHierarchy: true,
  },
  verdikto: {
    label: 'Verdikto',
  },
  dex: {
    label: 'DEX',
  },
  ksebe: {
    label: 'Ksebe',
  },
  robo: {
    label: 'ROBO',
  },
  capila: {
    label: 'Capila',
  },
})

export const legalForms = generateKeys({
  employee: {
    label: 'Employee (TPP)',
    isEmployment: true,
  },
  student: {
    label: 'Student (BPS)',
    isEmployment: true,
  },
  company: {
    label: 'Company (SRO)',
    isEmployment: false,
  },
  nonItConsultant: {
    label: 'Non-IT Consultant (SZCO)',
    isEmployment: false,
  },
  nonItFreelancerCombi: {
    label: 'Non-IT Freelancer Combi (SZCO)',
    isEmployment: false,
  },
  itFreelancerActive: {
    label: 'IT Freelancer Active (SZCO)',
    isEmployment: false,
  },
  nonItFreelancerActive: {
    label: 'Non-IT Freelancer Active (SZCO)',
    isEmployment: false,
  },
  itFreelancerCombi: {
    label: 'IT Freelancer Combi (SZCO)',
    isEmployment: false,
  },
  author: {
    label: 'Author (FO)',
    isEmployment: true,
  },
  serviceAgreement: {
    label: 'Service Agreement (DoPC FO)',
    isEmployment: true,
  },
  oneTimeWork: {
    label: 'One-time Work (DoVP FO)',
    isEmployment: true,
  },
  privateEntrepreneur: {
    label: 'Private Entrepreneur (KATA)',
    isEmployment: false,
  },
  itCompany: {
    label: 'IT Company (SRO)',
    isEmployment: false,
  },
  itFreelancerActiveSk: {
    label: 'IT Freelancer Active SK (SZCO)',
    isEmployment: false,
  },
  formerKata: {
    label: 'Former KATA',
    isEmployment: false,
  },
})

export const salaryForms = generateKeys({
  pph: {
    label: 'Pay per Hour',
    abbr: 'PPH',
  },
  fst: {
    label: 'Fixed Salary & T',
    abbr: 'FST',
  },
  fs: {
    label: 'Fixed Salary',
    abbr: 'FS',
  },
})

export const maritalStatuses = generateKeys({
  single: {
    label: 'Single',
  },
  married: {
    label: 'Married',
  },
  divorced: {
    label: 'Divorced',
  },
  widowed: {
    label: 'Widowed',
  },
})

export const healthInsurances = generateKeys({
  vszp: {
    label: 'SK / VšZP',
  },
  union: {
    label: 'SK / Union',
  },
  dovera: {
    label: 'SK / Dôvera',
  },
  neak: {
    label: 'HU / NEAK',
  },
  rbp: {
    label: 'CZ / RBP',
  },
  vzp: {
    label: 'CZ / VZP',
  },
  czVojenskaZdravotniPojistovnaCr: {
    label: 'CZ / Vojenská zdravotní pojišťovna ČR',
  },
  czCeskaPrumyslovaZdravotniPojistovna: {
    label: 'CZ / Česká průmyslová zdravotní pojišťovna',
  },
  czOborZdravPojistovnaZamestnancuBankPojistovenAStavebnictvi: {
    label:
      'CZ / Obor. zdrav. pojišťovna zaměstnanců bank, pojišťoven a stavebnictví',
  },
  czZamestnaneckaPojistovnaSkoda: {
    label: 'CZ / Zaměstnanecká pojišťovna Škoda',
  },
  czZdravotniPojistovnaMinisterstvaVnitraCr: {
    label: 'CZ / Zdravotní pojišťovna ministerstva vnitra ČR',
  },
  czRevirniBratrskaPokladnaZdravotniPojistovna: {
    label: 'CZ / Revírní bratrská pokladna, zdravotní pojišťovna',
  },
  czMaxima: {
    label: 'CZ / Maxima',
  },
})

export const officeCountries = {
  slovakia: 'Slovakia',
  czechia: 'Czechia',
  hungary: 'Hungary',
  other: 'Other',
}

export const offices = generateKeys({
  bratislava: {
    label: 'Bratislava',
    country: officeCountries.slovakia,
  },
  kosice: {
    label: 'Kosice',
    country: officeCountries.slovakia,
  },
  presov: {
    label: 'Presov',
    country: officeCountries.slovakia,
  },
  praha: {
    label: 'Prague',
    country: officeCountries.czechia,
  },
  brno: {
    label: 'Brno',
    country: officeCountries.czechia,
  },
  budapest: {
    label: 'Budapest',
    country: officeCountries.hungary,
  },
  remoteSK: {
    label: 'Remote/SK',
    country: officeCountries.slovakia,
  },
  remoteCZ: {
    label: 'Remote/CZ',
    country: officeCountries.czechia,
  },
  remoteHU: {
    label: 'Remote/HU',
    country: officeCountries.hungary,
  },
  remoteOther: {
    label: 'Remote/Other',
    country: officeCountries.other,
  },
})

export const contractTypes = generateKeys({
  internal: {
    label: 'Internal',
    internalContract: true,
  },
  external: {
    label: 'External',
    internalContract: false,
  },
})

export const officesByCountry = groupBy(
  keys(offices),
  (key) => offices[key].country,
)

export const branches = generateKeys({
  vlsk: {
    label: 'Spot',
  },
  vlcz: {
    label: 'VL-CZ',
  },
  vlee: {
    label: 'VL-EST',
  },
  vlhu: {
    label: 'VL-HU',
  },
  rucz: {
    label: 'Reactive',
  },
  spsk: {
    label: 'Sparring SK',
  },
  ltsk: {
    label: 'LAW-TECH',
  },
  salv: {
    label: 'Salvus',
  },
  spcz: {
    label: 'Sparring CZ',
  },
  spot: {
    label: '(old) The Spot BA',
  },
  spotHu: {
    label: 'The Spot HU',
  },
  vask: {
    label: 'VL-SK',
  },
  igor: {
    label: 'Igor Demcak-advokat',
  },
  winc: {
    label: 'Wincent',
  },
  rich: {
    label: 'Tomas Richter Urban-euroadvokat',
  },
  vltech: {
    label: 'VL-Tech',
  },
  vlhk: {
    label: 'VL-HK',
  },
  hold: {
    label: 'Vacuumlabs JSA',
  },
  trm: {
    label: 'TramaTM',
  },
  trmlg: {
    label: 'Trama Legal',
  },
  rosk: {
    label: 'ROBO',
  },
  cask: {
    label: 'Capila',
  },
  nuag: {
    label: 'NuFi',
  },
  wing: {
    label: 'Wingriders',
  },
  veronikaVasekova: {
    label: 'Veronika Vaseková',
  },
})

export const branches2 = generateKeys({
  vltech: {
    label: 'VL-Tech',
  },
})

export const genders = generateKeys({
  female: {
    label: 'Female',
  },
  male: {
    label: 'Male',
  },
  nonBinary: {
    label: 'Non-Binary',
  },
})

export const multiFilters = {
  simple: 'simple',
  multi: 'multi',
}

export const subcontractTypes = generateKeys({
  payment: {
    label: 'Payment',
    editHelpMessage:
      'Contractor will be paid the Salary specified above and the difference between this Salary and Salary specified in main Contract',
  },
  reporting: {
    label: 'Reporting',
    editHelpMessage:
      '% of logged time that will be reported under selected Department, the rest will be reported under Deparment in main Contract',
  },
})

export const leaveReasons = generateKeys({
  underperforming: {
    label: 'Underperforming',
  },
  cancelled: {
    label: 'Cancelled position',
  },
  newChallenges: {
    label: 'New challenges',
  },
  unhappy: {
    label: 'Unhappy with VL',
  },
  school: {
    label: 'School as a priority',
  },
  personalIssues: {
    label: 'Personal issues',
  },
  parentalLeave: {
    label: 'Parental leave',
  },
  sabbatical: {
    label: 'Sabbatical',
  },
  benchSabbatical: {
    label: 'Bench Sabbatical',
  },
  talentTransfer: {
    label: 'Talent transfer',
  },
  notFitForTheTeam: {
    label: 'Not fit for the team',
  },
  betterSalary: {
    label: 'Better salary',
  },
  other: {
    label: 'Other',
  },
})

export const departure = generateKeys({
  involuntary: {
    label: 'Involuntary',
  },
  voluntary: {
    label: 'Voluntary',
  },
})

export const roles = generateKeys({
  dev: {
    label: 'Dev',
  },
  devOps: {
    label: 'DevOps',
  },
  css: {
    label: 'CSS',
  },
  tester: {
    label: 'Tester',
  },
  pm: {
    label: 'PM',
  },
  po: {
    label: 'PO',
  },
  productDesigner: {
    label: 'Product Designer',
  },
  visualDesigner: {
    label: 'Visual Designer',
  },
  serviceDesigner: {
    label: 'Service Designer',
  },
  uxResearcher: {
    label: 'UX Researcher',
  },
  productManager: {
    label: 'Product Manager',
  },
  dataScientist: {
    label: 'Data Scientist',
  },
  itRecruiter: {
    label: 'IT Recruiter',
  },
  nonItRecruiter: {
    label: 'Non-IT Recruiter',
  },
  sourcer: {
    label: 'Sourcer',
  },
  hrGeneral: {
    label: 'HR General',
  },
  hrOperations: {
    label: 'HR Operations',
  },
  benefits: {
    label: 'Benefits',
  },
  nonDevRecruitment: {
    label: 'Nondev Recruitment',
  },
  productLead: {
    label: 'Product Lead',
  },
  businessAnalyst: {
    label: 'Business Analyst',
  },
  businessStrategist: {
    label: 'Business Strategist',
  },
  mngSupport: {
    label: 'Mng & Support',
  },
  uxTechnicalWriter: {
    label: 'UX & Technical Writer',
  },
})

export const cateringForms = generateKeys({
  cantine: {
    label: 'Cantine',
  },
  cash: {
    label: 'Cash',
  },
  gastrocard: {
    label: 'Gastrocard',
  },
  monetaryContribution: {
    label: 'Monetary Contribution',
  },
})

export const entitlementValues = generateKeys({
  4: {
    label: '4',
  },
  6: {
    label: '6',
  },
})

export const currencyValues = generateKeys({
  EUR: { label: 'EUR', symbol: '€' },
  CZK: { label: 'CZK', symbol: 'Kč' },
  HUF: { label: 'HUF', symbol: 'Ft' },
  USD: { label: 'USD', symbol: '$' },
  GBP: { label: 'GBP', symbol: '£' },
  PLN: { label: 'PLN', symbol: 'zł' },
  CHF: { label: 'CHF', symbol: 'Fr.' },
  SEK: { label: 'SEK', symbol: 'kr' },
  HKD: { label: 'HKD', symbol: 'HK$' },
  INR: { label: 'INR', symbol: '₹' },
  NZD: { label: 'NZD', symbol: 'NZ$' },
  SGD: { label: 'SGD', symbol: 'S$' },
  PHP: { label: 'PHP', symbol: '₱' },
  JPY: { label: 'JPY', symbol: '¥' },
  BGN: { label: 'BGN', symbol: 'лв' },
})

export const invoicedServiceOptions = generateKeys({
  'Administratívne a ekonomické poradenstvo': {
    label: 'Administratívne a ekonomické poradenstvo',
  },
  'Administratívne práce': {
    label: 'Administratívne práce',
  },
  'HR konzultácie a administratívna činnosť': {
    label: 'HR konzultácie a administratívna činnosť',
  },
  'Konzultačné služby': {
    label: 'Konzultačné služby',
  },
  'Marketing a event konzultácie': {
    label: 'Marketing a event konzultácie',
  },
  'Marketingové a HR konzultácie': {
    label: 'Marketingové a HR konzultácie',
  },
  'Počítačové služby': {
    label: 'Počítačové služby',
  },
  'Služby projektového managementu a poradenstva pri budovaní dcérskej spoločnosti a vývoja nových produktov':
    {
      label:
        'Služby projektového managementu a poradenstva pri budovaní dcérskej spoločnosti a vývoja nových produktov',
    },
  'Multiple lines covering active and passive income split': {
    label:
      'Multiple lines covering active and passive income split (ONLY FOR IT AND NON-IT COMBI)',
  },
})
